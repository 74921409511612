import React, {
  useState
} from 'react';
import Client from 'insales-buy'

const defaultState = {
  cart: {},
};

const client = new Client(`${process.env.GATSBY_API_URL}`);

const CartContext = React.createContext(defaultState);

export default CartContext;

export function CartContextProvider({
  children
}) {
  const [checkout, setCheckout] = useState(
    JSON.parse(
      typeof window !== 'undefined' ? localStorage.getItem('checkout') : null
    )
  );

  const [successfulOrder, setSuccessfulOrder] = useState(null);
  // const checkoutId = checkout?.id;
  // const checkoutId = checkout?.items_count;

  React.useEffect(() => {
    const getCheckout = async () => {
      if (typeof window !== 'undefined') {
        const result = await client.cart.get();
        const fetchedCheckout = result?.data ? result.data : {}
        if (fetchedCheckout) {
          if (fetchedCheckout.completedAt) {
            //Если заказ оформлен, то удаляем корзину
            localStorage.removeItem('checkout');
            setCheckout(null);
            setSuccessfulOrder(fetchedCheckout);
          } else {
            //Перерисовываем корзину, сохраняем в localStorage
            setCheckout(fetchedCheckout);
            localStorage.setItem('checkout', JSON.stringify(fetchedCheckout));
          }
        }
      }
    };

    getCheckout();
  }, [setCheckout, setSuccessfulOrder,
    //  checkoutId
  ]);

  async function getProductById(productId) {
    const product = await client.product.get(productId);
    return product;
  }

  const updateLineItem = async ({
    variantId,
    quantity
  }) => {
    // if no checkout id, create a new checkout
    let newCheckout = checkout || {
      items: []
    } //(await client.cart.get());
    // check to see if this variantId exists in storedCheckout
    const lineItemVariant = newCheckout.items?.find(
      lineItem => lineItem.variant_id === variantId
    );

    if (lineItemVariant) {
      //todo: отладочный вариант - не учитываем уменьшение количества
      const deltaQuantity = quantity //+ lineItemVariant.quantity;

      if (deltaQuantity) {
        let newLine = {}
        newLine[variantId] = deltaQuantity
        let response = await client.cart.update([newLine])
        newCheckout = response.data
      } else {
        let response = await client.cart.remove([lineItemVariant.id])
        newCheckout = response.data;
      }
    } else {
      let newLine = {}
      newLine[variantId] = quantity
      let response = await client.cart.add([newLine])
      newCheckout = response.data;
    }
    setCheckout(newCheckout);
    setSuccessfulOrder(null);
    if (typeof window !== 'undefined') {
      localStorage.setItem('checkout', JSON.stringify(newCheckout));
    }
  };

  const removeLineItem = async (variantId, quantity) => {
    let response = await client.cart.remove(variantId, quantity);

    const newCheckout = response.data
    setCheckout(newCheckout);
  };

  const dismissSuccessfulOrder = () => {
    setSuccessfulOrder(null);
  };

  return (
    <CartContext.Provider value={
      {
        checkout,
        updateLineItem,
        removeLineItem,
        getProductById,
        successfulOrder,
        dismissSuccessfulOrder,
      }
    } >
      {children}
    </CartContext.Provider>
  );
}