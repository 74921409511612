import React from 'react';

import {
  COLORS,
  COLOR_MODE_KEY,
  INITIAL_COLOR_MODE_CSS_PROP,
} from 'styles/theme';

const isBrowser = typeof window !== "undefined"

export const ThemeContext = React.createContext();

export const UIThemeProvider = ({ children }) => {

  const [UIState, setUIState] = React.useState(undefined);

  React.useEffect(() => {
    const root = window.document.documentElement;

    // Because colors matter so much for the initial page view, we're
    // doing a lot of the work in gatsby-ssr. That way it can happen before
    // the React component tree mounts.
    const initialColorValue = root.style.getPropertyValue(
      INITIAL_COLOR_MODE_CSS_PROP
    );
    let newUIState = { ...UIState, ...{ "colorMode": initialColorValue }, ...{ "CollectionMenuOpen": false }, ...{ "UserMenuOpen": false }, }

    setUIState(newUIState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = React.useMemo(() => {
    function setColorMode(newValue) {
      if (isBrowser) {
        const root = window.document.documentElement;

        localStorage.setItem(COLOR_MODE_KEY, newValue);

        Object.entries(COLORS).forEach(([name, colorByTheme]) => {
          const cssVarName = `--color-${name}`;

          root.style.setProperty(cssVarName, colorByTheme[newValue]);
        });
        let newUIState = { ...UIState, ...{ "colorMode": newValue } }
        setUIState(newUIState);
      }
    }
    function setHiderMenuMode(newValue) {
      if (isBrowser) {
        let newUIState = { ...UIState, ...{ "hideMenuState": newValue } }
        setUIState(newUIState);
      }
    }
    function toggleCatalogMenu() {
      let newUIState = { ...UIState, ...{ "CollectionMenuOpen": !UIState.CollectionMenuOpen } }
      setUIState(newUIState);
    }
    function openCatalogMenu() {
      let newUIState = { ...UIState, ...{ "CollectionMenuOpen": true } }
      setUIState(newUIState);
    }
    function closeCatalogMenu() {
      let newUIState = { ...UIState, ...{ "CollectionMenuOpen": false } }
      setUIState(newUIState);
    }
    function openUserMenu() {
      let newUIState = { ...UIState, ...{ "UserMenuOpen": true } }
      setUIState(newUIState);
    }
    function closeUserMenu() {
      let newUIState = { ...UIState, ...{ "UserMenuOpen": false } }
      setUIState(newUIState);
    }
    function closeAllMenu() {
      let newUIState = { ...UIState, ...{ "UserMenuOpen": false }, ...{ "CollectionMenuOpen": false } }
      setUIState(newUIState);
    }
    return {
      UIState,
      setColorMode,
      setHiderMenuMode,
      toggleCatalogMenu,
      openCatalogMenu,
      closeCatalogMenu,
      openUserMenu,
      closeUserMenu,
      closeAllMenu,
    };
  }, [UIState, setUIState]);


  return (
    <ThemeContext.Provider value={
      contextValue
      //  ,modalState
    }>
      {children}
    </ThemeContext.Provider>
  );
};
