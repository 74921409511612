/** @private */
// var URL = require('@/tools/url').default;
/** @private */
import simpleFetch from '../simple_fetch/index.js';
/** @private */
// var lodash = require('lodash');

/**
 * @memberof module:ajaxAPI/cart
 * @alias add
 *
 * @description
 * Добавление товара в корзину.
 *
 * @param {Object} items -  объект с добавляемыми модификациями и их кол-вом {variant_id: quantity, ...}
 * @param {Object} options - объект с дополнительными полями: comments, coupon
 * @param {Object} options.comments - объект с комментариями вида {variant_id: comment, ...}
 * @param {string} options.coupon - название купона
 *
 * @return {$.ajax}
 *
 * @example
 * var items = {
 *   123456: 1,
 *   123457: 3,
 *   123450: 100
 * };
 *
 * var options = {
 *   comments: { 123456: 'Ваш комментарий' },
 *   coupon: 'test'
 * }
 *
 * ajaxAPI.cart.add(items, options)
 *  .done(function (onDone) { console.log ('onDone: ', onDone) })
 *  .fail(function (onFail) { console.log ('onFail:', onFail) });
 */

export default function add(items, options) {
    //   var _lang = URL.getKeyValue('lang') || '';
    var _lang = ''
    var fields = {
        lang: _lang
    };

    options = options || {
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    };

    if (Array.isArray(items)) {
        if (items.length > 1) {
            fields.variant_ids = {}
            items.forEach(item => {
                for (let prop in item) {
                    fields.variant_ids[`${prop}`] = item[prop]
                }
            })
        } else {
            for (let prop in items[0]) {
                fields.variant_id = prop
                fields.quantity = items[0][prop]
            }
        }
    }

    if (options.coupon) {
        fields['cart[coupon]'] = options.coupon;
    }

    fields['_method'] = "patch"
    return simpleFetch.post(`${this.url}/cart_items.json`, fields, options)
};