import React from 'react';
import { CartContextProvider } from './src/context/CartContext';
import { GlobalStyle } from './src/components/globalStyles';
import { ThemeProvider } from 'styled-components'
import { baseTheme } from 'styles/theme'
import { UIThemeProvider } from './src/context/ThemeContext'
import { LoaderContextProvider } from './src/context/LoaderContext'

// import './src/styles/global.css'

export const wrapRootElement = ({ element }) => {

  return (
    <ThemeProvider theme={baseTheme}>
      <UIThemeProvider>
        <CartContextProvider>
          <LoaderContextProvider>
            {element}
          </LoaderContextProvider>
        </CartContextProvider>
      </UIThemeProvider>
    </ThemeProvider >
  )
}



export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />
    {element}
  </>
);
