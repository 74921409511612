/**
 * @module ajaxAPI/product
 *
 * @description
 * Ajax-обертки для работы с товарами
 */


import get from './get.js'
// import getList from './getList.js'


export default class cart {
    constructor(url) {
        this.url = url;
    }
    get = get;
    // getList = getList;
};