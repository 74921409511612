/** @private */
// var URL = require('@/tools/url').default;
/** @private */
// var jquery = require('jquery');
/** @private */
// var lodash = require('lodash');
import simpleFetch from '../simple_fetch/index.js';

/**
 * @description
 * Обновление состава корзины.
 * Позволяет:
 * - обновить состав корзины
 * - удалить несколько позиций
 * - добавить несколько позиций
 * - изменить кол-во товаров позиции
 * - установить комментарии к позициям
 *
 * @param {Object} items - набор пар {variant_id: quantity, ...}. Если quantity = 0, то позиция удаляется из корзины, в противном случае устанавливается указанное кол-во
 * @param {Object} options - дополнительные поля: comments, coupon
 * @param {Object} options.comments - объект с комментариями вида {variant_id: comment, ...}
 * @param {String} options.coupon - название купона
 *
 * @return {$.ajax} Ajax запрос к корзине
 *
 * @example
 * var items = {
 *   123456: 1,
 *   123457: 3,
 *   123450: 100
 * };
 *
 * var options = {
 *   comments: { 123456: 'Ваш комментарий' },
 *   coupon: 'test'
 * }
 *
 * ajaxAPI.cart.update(items, optins)
 *  .done(function (onDone) { console.log('onDone: ', onDone) })
 *  .fail(function (onFail) { console.log('onFail: ', onFail) });
 */

export default function update(items, options) {

    var fields = {};
    options = options || {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include'
    };

    if (Array.isArray(items)) {
        items.forEach(item => {
            for (let variant_id in item) {
                fields['variant_ids[' + variant_id + ']'] = item[variant_id]
            }
        })
        
    }

    if (options.coupon) {
        fields['cart[coupon]'] = options.coupon;
    }

    fields['_method'] = "patch"
    let formData = new URLSearchParams(Object.entries(fields)).toString();
    return simpleFetch.post(`${this.url}/front_api/cart.json`, formData, options);
};