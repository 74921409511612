/** @private */
// var URL = require('@/tools/url').default;
/** @private */
// var jquery = require('jquery');
import simpleFetch from '../simple_fetch/index.js';

/**
 * @memberof module:ajaxAPI/cart
 * @alias get
 *
 * @description Получение состава корзины
 *
 * @return {$.ajax}
 *
 * @example
 * ajaxAPI.cart.get()
 *  .done(function (onDone) { console.log('onDone:', onDone) })
 *  .fail(function (onFail) { console.log('onFail:', onFail) });
 */

export default function get(options) {
    //   var _lang = URL.getKeyValue('lang') || '';
    var _lang = '';
    var fields = {
        lang: _lang
    };
    options = options || {
        credentials: 'include'
    }
    /*
     * В куке состав корзины хранится, если там не более 4х РАЗНЫХ модификаций
     * Кука может быть пустой - дергаем инфу с сервера
     * Если кука содержит строку 'json' - дергаю инфу с сервера
     */
    //todo: проверить как работают куки с другим доменом. Сайт находится на одном домене, а api на другом домене
    //todo: добавить сохранение id корзины, взятого из cookies
    if (typeof window !== 'undefined') console.log(document.cookie);

    return simpleFetch.get(`${this.url}/cart_items/v2.json`, {
        ...options,
        ...fields
    });
};