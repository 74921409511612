/** @private */
import simpleFetch from '../simple_fetch/index.js';

/**
 * @memberof module:ajaxAPI/cart
 * @alias remove
 *
 * @description Удаление товара из корзины.
 *
 * @param {Number} variant_id - id модификации
 *
 * @return {$.ajax}
 *
 * @example
 * ajaxAPI.cart.remove(123123)
 *  .done(function (onDone) { console.log('onDone: ', onDone) })
 *  .fail(function (onFail) { console.log('onFail: ', onFail) });
 */

export default function remove(variantId) {
    var path = `${this.url}/cart_items/${variantId}.json`;

    var options = {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    }
    var fields = {_method: "delete"};
    
    let formData = new URLSearchParams(Object.entries(fields)).toString();
    debugger
    return simpleFetch.post(path, formData, options);
};