/**
 * Модуль, объединяющий весь ajax
 */
import Cart from './cart/index.js';
import Product from './product/index.js';

export default class Client {
    constructor(url) {
        this.url = url;

        this.cart = new Cart(url)
        this.product = new Product(url);
    }
    //   cart = new Cart(this.url)
    //   compare: require('@/ajax/compare'),
    //   product: require('@/ajax/product'),
    //   checkout: require('@/ajax/checkout'),
    //   collection: require('@/ajax/collection'),
    //   shop: require('@/ajax/shop'),
};
