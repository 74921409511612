import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

export const GlobalStyle = createGlobalStyle`
  ${reset}
  select{
    height: 40px;
    font-size: 16px;
  }
  body{
    background: hsl(var(--color-background));
    color: hsl(var(--color-text));
    /* font-family: 'Open Sans', sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    /* font-family: "GTEestiPro",arial,sans-serif; */
    line-height: 1.2;
    /* overflow-x: hidden; */
    background-image: linear-gradient(180deg, #654EA3 0%, #7FAED9 100%);
  }


  strong{
    font-weight: bold;
  }
  h1,h2,h3,h4,h5,h6{
    margin: 20px 0;
    hyphens: auto;
  }
  h1{
    font-size: 3em;
  }
  h2{
    font-size: 2.5em;
  }
  h3{
    font-size: 2em;
  }
  h4{
    font-size: 1.5em;
  }
  h5{
    font-size: 1em;
  }
  h6{
    font-size: 0.75em;
  }
  ul{
    margin-left: 20px;
    >li{
      list-style: none;
    }
  }
  .BottomSheet__draggable__userStyle{
        top: 12vh;
        background: hsl(var(--color-background));
        color: hsl(var(--color-text));
        /* height: calc(100vh - 80px); */
        border-radius: 30px 30px 0px 0px;
}
.BottomSheet__window__userStyle{
    overflow-x: hidden;
    max-width: 100%;
    padding-bottom: 160px;
}

.BottomSheet__backdrop__userStyle{
    transition: 0.3s;
}

.BottomSheet__dragIndicator__indicatir__userStyle{
    width: 2.5rem;
    height: 6px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    cursor: ns-resize;
}
`
