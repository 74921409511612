// theme.ts

import { ITheme } from '../interfaces/styled'

export const COLORS = {
  text: {
    light: '0deg, 0%, 30%', // white
    dark: '0deg, 0%, 99%', // near-black
  },
  background: {
    light: '0deg, 0%, 97%', // white
    dark: '0deg, 0%, 13%', // 
  },
  primary: {
    light: '256deg, 35.3%, 47.3%', // klikli
    dark: '256deg, 35.3%, 47.3%', // still klikli
  },
  primaryHover: {
    light: '253deg, 71.4%, 41.2%', // klikli
    dark: '253deg, 71.4%, 41.2%', // still klikli
  },
  secondary: {
    light: '250deg, 100%, 50%', // Purplish-blue
    dark: '190deg, 100%, 40%', // Cyan
  },
  // Grays, scaling from least-noticeable to most-noticeable
  gray300: {
    light: 'hsl(0deg, 0%, 70%)',
    dark: 'hsl(0deg, 0%, 30%)',
  },
  gray500: {
    light: 'hsl(0deg, 0%, 50%)',
    dark: 'hsl(0deg, 0%, 50%)',
  },
  gray700: {
    light: 'hsl(0deg, 0%, 30%)',
    dark: 'hsl(0deg, 0%, 70%)',
  },
  gray300hsl: {
    light: '0deg, 0%, 70%',
    dark: '0deg, 0%, 30%',
  },
  gray500hsl: {
    light: '0deg, 0%, 50%',
    dark: '0deg, 0%, 50%',
  },
  gray700hsl: {
    light: '0deg, 0%, 30%',
    dark: '0deg, 0%, 70%',
  },
  blurredBackground: {
    light: 'rgba(0, 0, 0, 0.35)',
    dark: 'rgba(128, 128, 128, 0.35)',
  }
};

export const baseTheme: ITheme = {
  colors: {
    primary: '#654ea3', //klikli
    secondary: '#2b2b2b',
    success: '#4caf50',
    danger: '#f44336 ',
    white: '#fff',
    bg: '#E5E4E8',
    font: '#19191B',
  },
  media: {
    extraLarge: '(min-width: 1140px)',
    large: '(min-width: 1024px)',
    medium: '(min-width: 768px)',
    small: '(min-width: 384px)',
    largeOnly: '(min-width: 1024px) and (max-width: 1139px)',
    mediumOnly: '(min-width: 768px) and (max-width: 1023px)',
    smallOnly: '(max-width: 767px)',
  },
  // in px
  sizes: {
    header: {
      height: 56
    },
    container: {
      width: 1200
    },
    footer: {
      height: 128
    },
    modal: {
      width: 540
    },
  },

  // in ms
  durations: {
    ms300: 300,
  },

  // z-index
  order: {
    header: 19,
    menuHider: 99,
    modal: 100,
  },
  // border-radius
  radius: {
    tiny: '4px',
    small: '6px',
    medium: '8px',
    large: '10px',
    huge: '16px'
  },
  // top-margin: 
  topMargins: {
    extraLarge: '70px',
    large: '70px',
    medium: '70px',
    small: '0',
  },
  // shadows
  shadows: {
    noShadow: '0px 0px 0px 0px transparent !important',
    tiny: '0 4px 8px 0 hsla(var(--color-gray700hsl), 0.05)',
    small: '0 4px 8px 0 hsla(var(--color-gray700hsl), 0.1)',
    medium: '0 4px 18px 0 hsla(var(--color-gray700hsl), 0.12), 0 2px 14px 0 hsla(var(--color-gray700hsl), 0.08)',
    large: '0 5px 30px 0 hsla(var(--color-gray700hsl), 0.11), 0 5px 15px 0 hsla(var(--color-gray700hsl), 0.08)',
    huge: '0 5px 50px 0 hsla(var(--color-gray700hsl), 0.15), 0 7px 16px 0 hsla(var(--color-gray700hsl), 0.08)',
  }
}

export const lightTheme = {
  body: '#E2E2E2',
  text: '#363537',
  toggleBorder: '#FFF',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
  ...baseTheme
}

export const darkTheme = {
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  gradient: 'linear-gradient(#091236, #1E215D)',
  ...baseTheme
}



export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';