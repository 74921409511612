import React, {
  useState
} from 'react';

const defaultState = {
  loading: false,
  showBackground: false
};


const LoaderContext = React.createContext();

export default LoaderContext;

export const LoaderContextProvider = ({ children }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isBackground, setIsBackground] = useState(false);

  return (
    <LoaderContext.Provider value={
      {
        LoaderContext,
        isLoading,
        isBackground,
        setIsLoading,
        setIsBackground
      }
    } >
      {children}
    </LoaderContext.Provider>
  );
}