/**
 * @module ajaxAPI/cart
 *
 * @description
 * Ajax-обертки для работы с корзиной
 */

import get from './get.js'
import add from './add.js'
import  update from './update.js'
import  remove from './remove.js'

export default class cart {
    constructor(url) {
        this.url = url;
    }

    get = get;
    add = add;
    update = update;
    remove = remove;
};

// export default cart;
