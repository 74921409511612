/** @private */
// var URL = require('@/tools/url').default;
/** @private */
// var jquery = require('jquery');
import simpleFetch from '../simple_fetch/index.js';

/**
 * @memberof module:ajaxAPI/product
 * @alias get

 * @description
 * Получение информации о товаре
 *
 * @param {number} id - id товара
 *
 * @return {$.ajax}
 *
 * @example
 * ajaxAPI.product.get(123456)
 *  .done(function (onDone) { console.log('onDone: ', onDone) })
 *  .fail(function (onFail) { console.log('onFail: ', onFail) });
 */

export default async function get(id) {

    var _lang = '';
    var fields = {
        lang: _lang,
        format: 'json'
    };
    var options = {
        headers: {
             'accept': 'application/json' 
        },
        customCache: true
    }
    
    let result = await simpleFetch.get(`${this.url}/products_by_id/${parseInt(id, 10)}.json`, { ...options, ...fields })
    return result.data.products[0]
};
